import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Observable, tap } from 'rxjs';
import { CrmService } from '../cil/crm/crm.service';
import { OrgService } from '../cil/org/org.service';
import { CrmItem, CrmProject, CrmProjectMetadata, CrmTable, Org, UserOrg } from '../shared/all.types';

export interface OrgStateModel {
  org: Org | null;
  members: UserOrg[] | null;
  orgs: Org[] | null;
  crmProjects: CrmProject[] | null;
  crmProjectsMetadata: CrmProjectMetadata[] | null;
  crmProject: CrmProject | null;
  crmTable: CrmTable | null;
  isCompletionTooltipDisplayed: boolean;
}

export class GetOrg {
  static readonly type = 'ORG/GET_ORG';

  constructor(public id: number) {}
}

export class LeaveOrg {
  static readonly type = 'ORG/LEAVE_ORG';
}

export class CreateOrg {
  static readonly type = 'ORG/CREATE_ORG';

  constructor(public payload: any) {}
}

export class UpdateOrg {
  static readonly type = 'ORG/UPDATE_ORG';

  constructor(
    public payload: any,
    public orgId?: number,
  ) {}
}

export class GetMembers {
  static readonly type = 'ORG/GET_ALL_MEMBERS';
}

export class AddMember {
  static readonly type = 'ORG/ADD_MEMBER';

  constructor(
    public orgId: number,
    public payload: any,
    public refreshOrgs?: boolean,
  ) {}
}

export class UpdateMember {
  static readonly type = 'ORG/UPDATE_MEMBER';

  constructor(
    public orgId: number,
    public userId: number,
    public payload: any,
  ) {}
}

export class DeleteMember {
  static readonly type = 'ORG/DELETE_MEMBER';

  constructor(
    public orgId: number,
    public userId: number,
  ) {}
}

export class InviteMember {
  static readonly type = 'ORG/INVITE_MEMBER';

  constructor(
    public orgId: number,
    public userId: number,
  ) {}
}

export class SaveOrgAvatar {
  static readonly type = 'ORG/SAVE_AVATAR';

  constructor(public orgId?: number) {}
}

export class GetOrgs {
  static readonly type = 'ORG/GET_ORGS';
}

export class GetCrmProjects {
  static readonly type = 'ORG/GET_CRM_PROJECTS';
}

export class GetCrmProjectsMetadata {
  static readonly type = 'ORG/GET_CRM_PROJECTS_METADATA';
}

export class SetCrmProject {
  static readonly type = 'ORG/SET_CRM_PROJECT';

  constructor(public crmProject: CrmProject | null) {}
}

export class GetCrmTable {
  static readonly type = 'ORG/GET_CRM_TABLE';

  constructor(
    public crmProjectId: number,
    public crmTableId: number,
    public page: number = 1,
  ) {}
}

export class AddCrmItem {
  static readonly type = 'ORG/ADD_CRM_ITEM';

  constructor(
    public crmProjectId: number,
    public crmTableId: number,
    public body: any, // TODO CRM: type this
  ) {}
}

export class UpdateCrmItem {
  static readonly type = 'ORG/UPDATE_CRM_ITEM';

  constructor(
    public crmProjectId: number,
    public crmTableId: number,
    public crmItemId: number,
    public body: any, // TODO CRM: type this
  ) {}
}

export class DeleteCrmItem {
  static readonly type = 'ORG/DELETE_CRM_ITEM';

  constructor(
    public crmProjectId: number,
    public crmTableId: number,
    public crmItemId: number,
  ) {}
}

export class ChangeCompletionTooltipDisplayed {
  static readonly type = 'ORG/CHANGE_COMPLETION_TOOLTIP_DISPLAYED';

  constructor(public value: boolean) {}
}

@State<OrgStateModel>({
  name: 'org',
  defaults: {
    org: null,
    members: null,
    orgs: null,
    crmProjects: null,
    crmProjectsMetadata: null,
    crmProject: null,
    crmTable: null,
    isCompletionTooltipDisplayed: false,
  },
})
@Injectable()
export class OrgState {
  @Selector()
  public static org(state: OrgStateModel): Org | null {
    return state.org;
  }

  @Selector()
  public static members(state: OrgStateModel): UserOrg[] | null {
    return state.members;
  }

  @Selector()
  public static orgs(state: OrgStateModel): Org[] | null {
    return state.orgs;
  }

  @Selector()
  public static crmProjects(state: OrgStateModel): CrmProject[] | null {
    return state.crmProjects;
  }

  @Selector()
  public static crmProjectsMetadata(state: OrgStateModel): CrmProjectMetadata[] | null {
    return state.crmProjectsMetadata;
  }

  @Selector()
  public static crmProject(state: OrgStateModel): CrmProject | null {
    return state.crmProject;
  }

  @Selector()
  public static crmTable(state: OrgStateModel): CrmTable | null {
    return state.crmTable;
  }

  @Selector()
  public static isCompletionTooltipDisplayed(state: OrgStateModel): boolean {
    return state.isCompletionTooltipDisplayed;
  }

  constructor(
    private readonly orgService: OrgService,
    private readonly crmService: CrmService,
    private readonly store: Store,
  ) {}

  @Action(ChangeCompletionTooltipDisplayed)
  public changeCompletionTooltipDisplayed(
    ctx: StateContext<OrgStateModel>,
    action: ChangeCompletionTooltipDisplayed,
  ): void {
    ctx.patchState({
      isCompletionTooltipDisplayed: action.value,
    });
  }

  @Action(GetOrg)
  public getOrg(ctx: StateContext<OrgStateModel>, action: GetOrg): Observable<Org> {
    return this.orgService.getOrg(action.id).pipe(
      tap((result: Org) => {
        ctx.patchState({
          org: result,
          members: result.userOrgs,
        });
      }),
    );
  }

  @Action(LeaveOrg)
  public leaveOrg(ctx: StateContext<OrgStateModel>): void {
    ctx.patchState({
      org: null,
    });
  }

  @Action(GetCrmProjects)
  public getCrmProjects(ctx: StateContext<OrgStateModel>): Observable<CrmProject[]> {
    ctx.patchState({
      crmProjects: null,
    });

    const org = this.store.selectSnapshot(OrgState.org);

    return this.crmService.getCrmProjects(org!.id!).pipe(
      tap((result: CrmProject[]) => {
        ctx.patchState({
          crmProjects: result || [],
        });
      }),
    );
  }

  @Action(GetCrmProjectsMetadata)
  public getCrmProjectsMetadata(ctx: StateContext<OrgStateModel>): Observable<CrmProjectMetadata[]> {
    ctx.patchState({
      crmProjectsMetadata: null,
    });

    const org = this.store.selectSnapshot(OrgState.org);

    return this.crmService.getCrmProjectsMetadata(org!.id!).pipe(
      tap((result: CrmProjectMetadata[]) => {
        ctx.patchState({
          crmProjectsMetadata: result || [],
        });
      }),
    );
  }

  @Action(SetCrmProject)
  public setCrmProject(ctx: StateContext<OrgStateModel>, action: SetCrmProject): void {
    ctx.patchState({
      crmProject: action.crmProject,
    });

    if (action.crmProject === null) {
      ctx.patchState({
        crmTable: null,
      });
    }
  }

  @Action(GetCrmTable)
  public getCrmTable(ctx: StateContext<OrgStateModel>, action: GetCrmTable): Observable<CrmTable> {
    ctx.patchState({
      crmTable: null,
    });

    const org = this.store.selectSnapshot(OrgState.org);

    return this.crmService.getCrmTable(org!.id!, action.crmProjectId, action.crmTableId, action.page).pipe(
      tap((result: CrmTable) => {
        ctx.patchState({
          crmTable: result,
        });
      }),
    );
  }

  @Action(AddCrmItem)
  public addCrmItem(ctx: StateContext<OrgStateModel>, action: AddCrmItem): Observable<any> {
    const org = this.store.selectSnapshot(OrgState.org);

    return this.crmService.addCrmItem(org!.id!, action.crmProjectId, action.crmTableId, action.body);
  }

  @Action(UpdateCrmItem)
  public updateCrmItem(ctx: StateContext<OrgStateModel>, action: UpdateCrmItem): Observable<CrmItem> {
    const org = this.store.selectSnapshot(OrgState.org);

    return this.crmService.updateCrmItem(
      org!.id!,
      action.crmProjectId,
      action.crmTableId,
      action.crmItemId,
      action.body,
    );
  }

  @Action(DeleteCrmItem)
  public deleteCrmItem(ctx: StateContext<OrgStateModel>, action: DeleteCrmItem): Observable<boolean> {
    const org = this.store.selectSnapshot(OrgState.org);

    return this.crmService.deleteCrmItem(org!.id!, action.crmProjectId, action.crmTableId, action.crmItemId);
  }

  @Action(SaveOrgAvatar)
  public saveOrgAvatar(ctx: StateContext<OrgStateModel>, action: SaveOrgAvatar): Observable<Org> {
    const state = ctx.getState();

    return this.orgService.saveAvatar(action.orgId || state.org!.id!).pipe(
      tap((result: Org) => {
        // Only patch the state if we are in an org (state.org)
        if (state.org?.id) {
          ctx.patchState({
            org: result,
          });
        }
      }),
    );
  }

  @Action(CreateOrg)
  public createOrg(ctx: StateContext<OrgStateModel>, action: CreateOrg): Observable<Org> {
    return this.orgService.createOrg(action.payload);
  }

  @Action(UpdateOrg)
  public updateOrg(ctx: StateContext<OrgStateModel>, action: UpdateOrg): Observable<Org> {
    const state = ctx.getState();

    return this.orgService.updateOrg(action.orgId || state.org!.id!, action.payload).pipe(
      tap((result: Org) => {
        // Only patch the state if we are in an org (state.org)
        if (result.id === state.org?.id) {
          ctx.patchState({
            org: result,
          });
        }
      }),
    );
  }

  @Action(GetOrgs)
  public getOrgs(ctx: StateContext<OrgStateModel>): Observable<Org[]> {
    ctx.patchState({
      orgs: null,
    });

    const org = this.store.selectSnapshot(OrgState.org);

    return this.orgService.getOrgs(org?.id).pipe(
      tap((result: Org[]) => {
        ctx.patchState({
          orgs: result || [],
        });
      }),
    );
  }

  @Action(GetMembers)
  public getMembers(ctx: StateContext<OrgStateModel>): Observable<UserOrg[]> {
    ctx.patchState({
      members: null,
    });

    const org = this.store.selectSnapshot(OrgState.org);

    return this.orgService.getMembers(org?.id).pipe(
      tap((result: UserOrg[]) => {
        ctx.patchState({
          members: result || [],
        });
      }),
    );
  }

  @Action(AddMember)
  public addUserToOrg(ctx: StateContext<OrgStateModel>, action: AddMember): Observable<Org> {
    return this.orgService.addMember(action.orgId, action.payload).pipe(
      tap({
        next: () => {
          if (!!action.refreshOrgs) {
            ctx.dispatch(new GetOrgs());
          } else {
            ctx.dispatch(new GetMembers());
          }
        },
      }),
    );
  }

  @Action(UpdateMember)
  public updateOrgUser(ctx: StateContext<OrgStateModel>, action: UpdateMember): Observable<UserOrg> {
    return this.orgService.updateMember(action.orgId, action.userId, action.payload).pipe(
      tap({
        next: () => {
          ctx.dispatch(new GetMembers());
        },
      }),
    );
  }

  @Action(DeleteMember)
  public deleteOrgUser(ctx: StateContext<OrgStateModel>, action: DeleteMember): Observable<boolean> {
    return this.orgService.deleteMember(action.orgId, action.userId).pipe(
      tap({
        next: () => {
          ctx.dispatch(new GetMembers());
        },
      }),
    );
  }

  @Action(InviteMember)
  public inviteOrgUser(ctx: StateContext<OrgStateModel>, action: InviteMember): Observable<boolean> {
    return this.orgService.inviteMember(action.orgId, action.userId);
  }
}
