import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CrmItemsPerPage } from '../../shared/all.constants';
import { CrmItem, CrmProject, CrmProjectMetadata, CrmTable, ExportJob } from '../../shared/all.types';
import { FileFieldValue } from '../../shared/crm.helpers';

@Injectable()
export class CrmService {
  private _getBaseUrl(
    orgId: number,
    crmProjectId?: number,
    crmTableId?: number,
    crmItemId?: number,
    propertyId?: number,
  ): string {
    const baseUrl = `${environment.api.baseUrl}/orgs/${orgId}`;
    const property = propertyId ? `/properties/${propertyId}` : '';
    const crmProject = crmProjectId ? `/crm/projects/${crmProjectId}` : '';
    const crmTable = crmTableId ? `/tables/${crmTableId}` : '';
    const crmItem = crmItemId ? `/items/${crmItemId}` : '';

    return baseUrl + property + crmProject + crmTable + crmItem;
  }

  constructor(private readonly http: HttpClient) {}

  public getCrmProjects(orgId: number): Observable<CrmProject[]> {
    const url = this._getBaseUrl(orgId) + '/crm/projects';

    return this.http.get<CrmProject[]>(url, { withCredentials: true });
  }

  public getCrmTable(
    orgId: number,
    crmProjectId: number,
    crmTableId: number,
    page: number = 1,
    search: string = '',
    orderBy?: { field: string; order: number },
    filters?: any,
  ): Observable<CrmTable> {
    const url = this._getBaseUrl(orgId, crmProjectId, crmTableId);

    return this.http.post<CrmTable>(
      url,
      { page, perPage: CrmItemsPerPage, search, orderBy, filters },
      { withCredentials: true },
    );
  }

  public uploadFileCrm(formData: FormData, orgId: number, crmProjectId: number): Observable<Required<FileFieldValue>> {
    const url = this._getBaseUrl(orgId, crmProjectId) + '/upload-file';

    return this.http.post<Required<FileFieldValue>>(url, formData, { withCredentials: true });
  }

  public addCrmItem(
    orgId: number,
    crmProjectId: number,
    crmTableId: number,
    body: any, // TODO CRM: type this
  ): Observable<CrmTable> {
    const url = this._getBaseUrl(orgId, crmProjectId, crmTableId) + '/item';

    return this.http.post<CrmTable>(url, body, { withCredentials: true });
  }

  public updateCrmItem(
    orgId: number,
    crmProjectId: number,
    crmTableId: number,
    crmItemId: number,
    body: any, // TODO CRM: type this
  ): Observable<CrmItem> {
    const url = this._getBaseUrl(orgId, crmProjectId, crmTableId, crmItemId);

    return this.http.put<CrmItem>(url, body, {
      withCredentials: true,
    });
  }

  public downloadFileCrmItem(
    orgId: number,
    crmProjectId: number,
    crmTableId: number,
    crmItemId: number,
  ): Observable<Blob> {
    const url = this._getBaseUrl(orgId, crmProjectId, crmTableId, crmItemId) + '/download';

    return this.http.get(url, { responseType: 'blob', withCredentials: true });
  }

  public deleteCrmItem(
    orgId: number,
    crmProjectId: number,
    crmTableId: number,
    crmItemId: number,
  ): Observable<boolean> {
    const url = this._getBaseUrl(orgId, crmProjectId, crmTableId, crmItemId);

    return this.http.delete<boolean>(url, { withCredentials: true });
  }

  public getTableRowHistory(
    orgId: number,
    crmProjectId: number,
    crmTableId: number,
    crmItemId: number,
    propertyId?: number,
  ): Observable<string[]> {
    const url = this._getBaseUrl(orgId, crmProjectId, crmTableId, crmItemId, propertyId) + '/history';

    return this.http.get<string[]>(url, { withCredentials: true });
  }

  public getItemFieldHistory(
    orgId: number,
    crmProjectId: number,
    crmTableId: number,
    crmItemId: number,
    crmFieldId: number,
    propertyId?: number,
  ): Observable<string[]> {
    const url = this._getBaseUrl(orgId, crmProjectId, crmTableId, crmItemId, propertyId) + '/history/' + crmFieldId;

    return this.http.get<string[]>(url, { withCredentials: true });
  }

  public checkCrmItemDuplicate(
    orgId: number,
    crmProjectId: number,
    crmTableId: number,
    partialItem: Partial<CrmItem>,
  ): Observable<boolean> {
    return this.http.put<boolean>(this._getBaseUrl(orgId, crmProjectId, crmTableId) + '/check-duplicate', partialItem, {
      withCredentials: true,
    });
  }

  public exportCrmTable(
    orgId: number,
    crmProjectId: number,
    crmTableId: number,
    fileName: string,
    search: string = '',
    orderBy?: { field: string; order: number },
    filters?: any,
  ): Observable<ExportJob> {
    return this.http.post<ExportJob>(
      this._getBaseUrl(orgId, crmProjectId, crmTableId) + '/csv',
      { search, orderBy, filters, fileName },
      { withCredentials: true },
    );
  }

  public getCrmProjectsMetadata(orgId: number): Observable<CrmProjectMetadata[]> {
    const url = `${this._getBaseUrl(orgId)}/crm-projects-metadata`;

    return this.http.get<CrmProjectMetadata[]>(url, { withCredentials: true });
  }

  public exportCrmProjectOrgs(orgId: number, crmProjectId: number): Observable<Blob> {
    return this.http.get<Blob>(`${this._getBaseUrl(orgId, crmProjectId)}/export-orgs`, {
      withCredentials: true,
      responseType: 'blob' as 'json',
    });
  }

  public exportCrmProjectProperties(orgId: number, crmProjectId: number): Observable<Blob> {
    return this.http.get<Blob>(`${this._getBaseUrl(orgId, crmProjectId)}/export-properties`, {
      withCredentials: true,
      responseType: 'blob' as 'json',
    });
  }

  public customAction(
    orgId: number,
    crmProjectId: number,
    crmTableId: number,
    propertyId: number,
    crmFieldId: number,
    actionName: string,
    body?: any,
  ): Observable<CrmTable> {
    const url =
      this._getBaseUrl(orgId, crmProjectId, crmTableId, undefined, propertyId) +
      `/custom-action/${crmFieldId}?name=${actionName}`;

    return this.http.post<CrmTable>(url, body, { withCredentials: true });
  }
}
